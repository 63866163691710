<template>
<div  class="dic_page_class">
  <el-row>
    <el-col id="add" :span="11">
      <div id="one">
        <el-form class="queryFrom" :inline="true">
          <el-form-item label="方案名称:" prop="name">
            <el-input
              v-model="queryParams.name"
              placeholder="方案名称"
            ></el-input>
          </el-form-item>
          <div>
            <el-button
              id="anniu"
              type="primary"
              icon="el-icon-plus"
              @click="addrole()"
              >添加方案
            </el-button>
            <el-button
              id="anniu1"
              type="primary"
              icon="el-icon-search"
              @click="onSubmit"
              >查询</el-button
            >
            <el-button
              id="anniu1"
              type="primary"
              icon="el-icon-refresh-right"
              @click="resetData"
              >重置</el-button
            >
          </div>
        </el-form>
        <el-dialog
          title="添加方案"
          :show-close="true"
          :close-on-click-modal="false"
          :visible.sync="dialogVisibleadd"
        >
          <add-scheme
            :addrole="1"
            :total="total"
            ref="role"
            @closeDialog="cancel"
          ></add-scheme>
          <span slot="footer" class="dialog-footer">
            <el-button type="success" @click="cancel()">取消</el-button>
            <el-button type="primary" @click="add">添加</el-button>
          </span>
        </el-dialog>

        <p id="bd">
          <img src="@/static/system/u977.svg"/>
          <span style="color: #565c5f">当前选择:</span>
          <span id="select">{{ currentSelection }}</span>
          <a id="navname" v-if="!isHidden" @click="deselect">取消选择 </a>
        </p>
      </div>
      <!-- 添加字典弹框 -->
      <el-table
        id="table"
        @current-change="handleCurrentChange"
        ref="singleTable"
        :highlight-current-row="true"
        @row-click="getDetails"
        :data="list"
        :header-cell-style="tableHeader"
        :cell-style="tableCell"
        border
      >
        <el-table-column type="index" width="50" label="编号" align="center"></el-table-column>
        <el-table-column prop="name" label="方案名称"></el-table-column>
        <el-table-column prop="rules" label="方案规则">
          <template slot-scope="scope">
            <div>
              <span v-for="(item, index) in scope.row.rules" :key="item.id" >{{item.depict}} 
                <span v-if="index < scope.row.rules.length-1"> + </span> </span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div id="two">
        <template>
          <el-pagination
            id="page"
            background
            @size-change="handleSizeChange"
            @current-change="pageChange"
            :page-sizes="[10, 15, 20]"
            :page-size="queryParams.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </template>
      </div>
    </el-col>
    <el-col :span="12">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <!-- 字典信息 -->
        <el-tab-pane label="方案信息" :model="userlist" class="showBox">
          <el-row id="msg" type="flex">
            <el-col :xs="24" :sm="24" :md="24">
              <el-form
                :inline="true"
                :model="ruleForm"
                ref="ruleForm"
                label-width="80px"
                class="demo-form-inline"
              >
                <el-form-item label="方案名称:" prop="name">
                  <el-input v-model="userlist.name"></el-input>
                </el-form-item>
                <el-form-item label="方案描述:" prop="remark">
                  <el-input
                    type="textarea"
                    :rows="2"
                    placeholder="请输入内容"
                    v-model="userlist.remark"
                  >
                  </el-input>
                </el-form-item>
              </el-form>
              <el-form label-width="80px" ref="userlist" :model="userlist" >
                  <el-form-item  v-for="(item, index) in userlist.rules" :key="index" :label="index+1+':'" >
                    <el-select v-model="item.id" placeholder="请选择规则">
                      <el-option v-for="rule in ruleList" :key="rule.id" :label="rule.depict" :value="rule.id"></el-option>
                    </el-select>
                    <i class="el-icon-circle-plus-outline" @click="addRule(userlist.rules, index)"></i>
                    <i class="el-icon-circle-close" v-if="index != 0" @click="rmRule(userlist.rules, index)"></i>
                  </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="updatePaidPlan">保存</el-button>
                </el-form-item>
              </el-form>
            </el-col>
          </el-row>
        </el-tab-pane>
      </el-tabs>
    </el-col>
  </el-row>
  </div>
</template>

<script>
import addScheme from "./addScheme";
export default {
  data() {
    return {
      activeName: "second",
      dialogVisibleadd: false,
      dialogVisible: false,
      dialogVisibledata: false,
      currentSelection: "",
      activeName: "second",
      groupMap: {},
      userlist: {
        id: "",
        name: "",
        remark: "",
        rules: []
      },

      formInline: {
        id: "",
        name: "",
        status: "",
        timestamp: "",
        details: "",
      },
      total: 0,
      queryParams: {
        pageNo: 1,
        pageSize: 10,
        name: "",
      },
      ruleForm: {},
      isHidden: true,
      userListPageSize: 10,
      userListPageNo: 1,
      userListTotal: 0,
      activeName: "",
      list: [],
      listuser: [],
      roleid: "",
      role: "",
      addUser: false,
	  funArr:[],
      ruleList: []
    };
  },
  computed: {},

  mounted: function () {
    this.loadList();
   
  },
  created:function(){
  	this.menuPermission()

  },
  methods: {
    addRule(rules, index){
      rules.splice(index+1, 0, {id: ''});
    },
    rmRule(rules, index){
      rules.splice(index, 1);
    },
	  async menuPermission() {

      await this.api.billingApi.listRules({}).then(res => {
        res = res.data;
        console.log(res);
        if(res.code == 200){
          this.ruleList = res.data;
        }
      })
	  	let name = "数据字典";
	  	let params = {
	  		id: this.$getCookie("sessionId"),
	  		menuName: name
	  	}
	  	let response = await this.api.platformApi.menuPermission(params);
	  	this.funArr = response.data.data;


	  },
    add() {
      console.log(this.$refs.role.submitForm);
      this.$refs.role.submitForm("ruleForm");
    },

    setCurrent(row) {
      this.$refs.singleTable.setCurrentRow(row);
    },
    getDetails(row, data) {
      // this.userlist = {...row};
      this.userlist = JSON.parse(JSON.stringify(row));
      let rules = row.rules;
      if(rules == null || rules == undefined || rules.length <= 0){
        this.userlist.rules = [{}];
      }
      this.roleid = row.id;
      this.role = row.name;
      this.currentSelection = row.name;
      this.loadlistuser();
      this.isHidden = false;
    },
    handleCreate(tab, event) {
      this.dialogVisible = true;
    },
    handleSizeChange(val) {
      this.queryParams.pageSize = val;
      this.queryParams.pageNo = 1;
      this.loadList();
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(val);
    },

    //查询字典列表并分页
    async loadList() {
      // var temp = this;
      // let response = await temp.api.platformApi.dictionariesList(temp.queryParams)
      //     temp.list = response.data.data.records;
      //     temp.total = response.data.data.total;
      await this.api.paidPlanApi.pagePaidPlan(this.queryParams).then(res => {
        res = res.data;
        console.log(res);
        if(res.code == 200){
          this.list = res.data.records;
          this.total = res.data.total;
        }else{
          this.$message.error(res.msg);
        }
      })
    },

    //查询字典明细
    async loadlistuser() {
      var list = this;
      console.log(list.listuser);
      var params = {
        pageNo: this.userListPageNo,
        pageSize: this.userListPageSize,
        id: this.roleid,
      };
      let response = await list.api.platformApi.dictionariesItemList(params)
          list.listuser = response.data.data.records;
          // list.userListPageNo = response.data.data.pages;
          // list.userListPageSize = response.data.data.size;
          list.userListTotal = response.data.data.total;
    },

    addaRoleUser() {
      this.$refs.addaRoleUser.addaRoleUser();
      this.addUser = false;
      this.loadlistuser();
      this.$forceUpdate();
    },

    //修改
    async submitForm(formName) {
      await this.$refs[formName].validate((valid) => {
        if (valid) {
          var params = {
            id: this.userlist.id,
            name: this.userlist.name,
            details: this.userlist.details,
            sessionId: this.$getCookie("sessionId"),
          };
         this.editDictionaries(params)
        } else {
          return false;
        }
      });
    },
    async editDictionaries(params){
         let response = await this.api.platformApi.editDictionaries(params)
         if(response.data.data= true){
           this.$message({
                message: "提交成功",
                type: "success",
              });
         }
    },
    tableHeader(column) {
      return "height:36px;background-color:#fafafa;color:#333333;padding:0";
    },
    tableCell(column) {
      return "height:40px;background-color:#fafafa;color:#000000;padding:0";
    },

    formatRole(row, column) {
      return row.status == "0" ? "是" : row.status == "1" ? "否" : "aaa";
    },
    onSubmit() {
      this.loadList();
    },
    // 更新规则
    async updatePaidPlan(){
      await this.api.paidPlanApi.updatePaidPlan(this.userlist).then(res => {
        res = res.data;
        console.log(res);
        if(res.code == 200){
          this.list = res.data;
          this.loadList();
          this.$message({
            message: res.msg,
            type: 'success'
          });
        }else{
          this.$message.error(res.msg);
        }
      });
      
    },
    resetData() {
      this.queryParams.name = '';
      this.loadList();
    },
    addrole() {
      this.dialogVisibleadd = true;
    },

    pageChange(val) {
      this.queryParams.pageNo = val;
      this.loadList();
    },
    cancel() {
      this.loadList();
      this.addUser = false;
      this.dialogVisibledata = false;
      this.dialogVisible = false;
      this.dialogVisibleadd = false;
    },
    handleClick(table, event) {},
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    findByRoleUser() {
      this.addUser = true;
    },
    deselect() {
      this.pageSwitch = false;
      this.currentSelection = "";
      this.isHidden = true;
      //this.$refs.tree.setCheckedKeys([])
    },
    insertrole() {
      this.$refs.rolevalue.submitForm("ruleForm");
      this.loadlistuser();
      this.dialogVisible = false;
    },
    userListHandleCurrentChange(val) {
      this.userListPageNo = val;
      this.loadlistuser();
    },
    userListHandleSizeChange(val) {
      this.userListPageSize = val;
      this.userListPageNo = 1;
      this.loadlistuser();
    },
    showLog(row) {
      this.ruleForm = row;
      this.dialogVisibledata = true;
    },
    confirm() {
      this.$refs.order.submitForm("ruleForm");
    },
  },
  components: {
    addScheme
  },
};
</script>
<style scoped>
/* .dic_page_class{
  height: 100%;
  width:1400px;
} */
.el-dialog__title {
  text-align: left;
}

>>> .el-dialog__body {
  border-top: 1px solid rgba(228, 228, 228, 1);
  border-bottom: 1px solid rgb(228, 228, 228, 1);
  padding-left: 0;
  padding-right: 0;
}

.block {
  text-align: right;
  margin-top: 15px;
}

#buttonbox button {
  margin-right: 15px;
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
}

#bt2 button {
  margin-right: 15px;
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
}

#bt1 {
  margin-right: 15px;
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
}

#back {
  background-color: white;
  padding: 15px 10px;
}

#msg button {
  margin-right: 15px;
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
}

#msg {
  background-color: white;
  padding: 15px 0 100px;
}

#page {
  float: right;
  margin-top: 15px;
}

#table {
  
  margin-top: 15px;
  background-color: rgba(29, 144, 255, 0);
}

#navname {
  margin-right: 220px;
  color: #409eff;
}

#select {
  display: inline-block;
}

#anniu {
 padding: 0px;
  width: 120px;
  height: 32px;
  border-radius: 5px;
}

#anniu1 {
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
}

#one {
  text-align: left;
}

.butT {
  float: left;
  margin-bottom: 2%;
}

#add {
  background-color: white;
  padding: 10px 20px 80px 20px;  
  margin-right: 20px;
}

#tablebox {
  width: 95%;
  margin-top: 15px;
  background-color: rgba(29, 144, 255, 0);
}

#bd {
  height: 40px;
  line-height: 40px;
  vertical-align: middle;
  background-color: #e6f7ff;
  border-radius: 5px;
  border: 1px solid #1d90ff;
  font-size: 16px;
  margin: 20px 0 10px 0;
}

#bd > span{
	vertical-align: middle;
	margin-right: 20px;
}

#bd > img{
	vertical-align: middle;
	margin:0 5px 0 10px;
}

.hiddenBox {
  visibility: hidden;
}

.showBox {
  background-color: white;
}

.titlebox {
  text-align: left;
}

.el-dialog button {
  margin-right: 15px;
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
}

form.el-form {
  text-align: initial;
}
/* */
>>> .el-icon-circle-plus-outline {
  font-size: 20px;
  margin-left: 20px;
  cursor: pointer;
}
>>> .el-icon-circle-close{
  font-size: 20px;
  margin-left: 20px;
  cursor: pointer;
}
</style>
