<template>
  <el-row type="flex" justify="center">
    <el-col :xs="12" :sm="16" :md="12">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="方案名称:" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="方案描述:" prop="remark">
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入内容"
            v-model="ruleForm.remark"
          >
          </el-input>
        </el-form-item>
      </el-form>
    </el-col>
  </el-row>
</template>  
<script>
export default {
  props: ["addrole", "total"],
  data() {
    return {
      ruleForm: {
        id: "",
        name: "",
        remark: "",
      },
      rules: {
        name: [
          { required: true, message: "方案名称不能为空", trigger: "blur" },
        ],
        remark: [
          { required: true, message: "方案描述不能为空", trigger: "blur" },
        ],
      },
    };
  },

  methods: {
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    async submitForm(formName) {
      await this.$refs[formName].validate((valid) => {
        if (valid) {
          this.createDictionaries(this.ruleForm);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    async createDictionaries(params) {
      let response = await this.api.paidPlanApi.savePaidPlan(params);
      if (response.data.code == 200) {
        this.$message({
          message: "添加成功",
          type: "success",
        });
      } else {
        this.$message({
          message: "添加失败",
          type: "warning",
        });
      }
      this.$emit("closeDialog");
      this.resetForm("ruleForm");
    },
  },
};
</script>

<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-select {
  width: 100%;
}
.el-cascader {
  width: 100%;
}
</style>